import "core-js/modules/es.function.name.js";
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-7eb7233d"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "nav"
};
var _hoisted_2 = {
  class: "nav-ul"
};
var _hoisted_3 = ["onClick"];
import { ref } from 'vue';
import { useRoute } from 'vue-router';
export default {
  setup: function setup(__props) {
    var route = useRoute();
    var menuList = ref([{
      id: 1,
      icon: 'HomeFilled',
      path: '/',
      name: '首页'
    }, {
      id: 2,
      icon: 'Reading',
      path: '/course',
      name: '我的课程'
    }, {
      id: 3,
      icon: 'Histogram',
      path: '/statistics',
      name: '学习轨迹'
    }, {
      id: 4,
      icon: 'UserFilled',
      path: '/me',
      name: '我'
    }]);
    var currentPath = ref(route.path);

    var savePath = function savePath(path) {
      currentPath.value = path;
    };

    return function (_ctx, _cache) {
      var _component_el_icon = _resolveComponent("el-icon");

      var _component_router_link = _resolveComponent("router-link");

      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("ul", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(menuList.value, function (item) {
        return _openBlock(), _createElementBlock("li", {
          key: item.id,
          onClick: function onClick($event) {
            return savePath(item.path);
          },
          class: _normalizeClass(currentPath.value === item.path ? 'active' : '')
        }, [_createVNode(_component_router_link, {
          to: item.path
        }, {
          default: _withCtx(function () {
            return [_createVNode(_component_el_icon, {
              style: {
                "font-size": "1.2rem"
              }
            }, {
              default: _withCtx(function () {
                return [(_openBlock(), _createBlock(_resolveDynamicComponent(item.icon)))];
              }),
              _: 2
            }, 1024), _createElementVNode("span", null, _toDisplayString(item.name), 1)];
          }),
          _: 2
        }, 1032, ["to"])], 10, _hoisted_3);
      }), 128))])]);
    };
  }
};