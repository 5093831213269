import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "common-layout"
};
import Footer from './footer.vue';
export default {
  setup: function setup(__props) {
    return function (_ctx, _cache) {
      var _component_router_view = _resolveComponent("router-view");

      var _component_el_main = _resolveComponent("el-main");

      var _component_el_footer = _resolveComponent("el-footer");

      var _component_el_container = _resolveComponent("el-container");

      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_container, null, {
        default: _withCtx(function () {
          return [_createVNode(_component_el_main, null, {
            default: _withCtx(function () {
              return [_createVNode(_component_router_view)];
            }),
            _: 1
          }), _createVNode(_component_el_footer, null, {
            default: _withCtx(function () {
              return [_createVNode(Footer)];
            }),
            _: 1
          })];
        }),
        _: 1
      })]);
    };
  }
};